<template>
    <div>
        <h3>{{ quizData.quizTitle }}</h3>
        <h4>Responses for {{ quizData.studentFirstName }} {{ quizData.studentLastName }}</h4>
        <h6>If you are denying an essay, you must include notes on why it is being denied. You may include notes for
            approval, but it is not required.</h6>
    </div>
    <div v-for="(question, qIdx) in quizData.questions" :key="qIdx"
         style="text-align: left; border: 1px solid black; margin-bottom: 1%; padding: 1%">
        <div v-html="question.questionText"></div>
        <p v-if="question.questionType === 'essay_question'">(Essay Question)</p>
        <div :id="`question-${question.idQuestion}`">
            <ul v-if="question.questionType !== 'essay_question'" style="list-style-type: none">
                <li v-for="(answer, ansIdx) in question.answers" :key="ansIdx">
                    <span :style="answer.userAnswered ? `background-color: #FFFACD` : ``"> {{ answer.answerText }}
                        <span :style="`${answer.correct ? 'color: green;' : 'color: red;'}`">
                            {{ answer.correct ? 'Correct' : 'Incorrect' }}
                        </span>
                  </span>
                </li>
            </ul>
            <div v-else @submit.prevent="updateEssay">
                <div v-for="(answer, ansIdx) in question.answers" :key="ansIdx">
                    <EssayReviewComponent :updating-user-id="authUser.idUser" :id-grade="idGrade"
                                          :essay-data="answer"
                                          @essayUpdate="updateEssayNotesList($event)"
                                          @validateForm="updatedValidatedForms($event, question.idQuestion)"
                    />
                </div>
            </div>

        </div>
    </div>
    <div v-if="canSubmit">
        <button @click="updateEssay" class="button btn-primary">Update Essay</button>
    </div>
</template>

<script>
import {ref, onBeforeMount, computed} from "vue";
import axios from "axios";
import {userQuizReport} from "@/Helpers/ApiCalls/QuizAPICalls";
import EssayReviewComponent from "@/components/Essay/EssayReviewComponent.vue";
import store from "@/store";
import {updateEssaysForQuizAndUser} from "@/Helpers/ApiCalls/EssayAPICalls";
import Swal from "sweetalert2";

export default {
    name: "UserQuizView",
    components: {EssayReviewComponent},
    props: {
        idUser: {
            type: Number,
            required: true,
        },
        idQuiz: {
            type: Number,
            required: true,
        },
        idGrade: {
            type: Number,
        },

    },
    setup(props) {
        const authToken = store.getters['auth/authenticated'];
        const authUser = store.getters['auth/user'];
        const quizData = ref({});
        const essayNotesList = ref({
            idGrade: parseInt(props.idGrade),
            notesList: []
        });
        const validatedNotesForms = ref([]);

        onBeforeMount(() => {
            getQuizData();
        })

        function updateEssayNotesList(event) {
            let note = event;
            let existing = essayNotesList.value.notesList.find(e => e.idEssayAnswer === note.idEssayAnswer);
            if (existing !== null && existing !== undefined) {
                let idx = essayNotesList.value.notesList.indexOf(existing);
                essayNotesList.value.notesList[idx] = note;
            } else {
                essayNotesList.value.notesList.push(note);
            }
        }

        function updatedValidatedForms(event, idQuestion) {
            let val = {question: idQuestion, validated: event};
            let existing = validatedNotesForms.value.find(n => n.question === idQuestion);
            if (existing !== null && existing !== undefined) {
                let idx = validatedNotesForms.value.indexOf(existing);
                validatedNotesForms.value[idx] = val;
            } else {
                validatedNotesForms.value.push(val);
            }
        }

        async function getQuizData() {
            await axios.get(userQuizReport(props.idQuiz, props.idUser))
                .then((result) => {
                    quizData.value = result.data;
                })
        }

        async function updateEssay() {
            await axios.put(updateEssaysForQuizAndUser(props.idUser, props.idQuiz), essayNotesList.value, {
                headers: {
                    "Authorization": `Bearer ${authToken.value}`
                }
            }).then((result) => {
                Swal.fire({
                    title: "Updated",
                    text: "If you denied any essay responses, the student will be asked to retake the quiz.",
                    confirmButtonText: "Okay",
                })
            })
        }

        const numberOfEssays = computed(() => {
            return quizData.value.questions.filter((q) => q.questionType === 'essay_question').length;
        })

        const canSubmit = computed(() => {
            return numberOfEssays.value > 0
                && numberOfEssays.value === essayNotesList.value.notesList.length
                && numberOfEssays.value === validatedCount.value;
        })

        const validatedCount = computed(() => {
            return validatedNotesForms.value.filter(vNoteForm => vNoteForm.validated === true).length;
        })

        return {
            quizData,
            authUser,
            canSubmit,
            updatedValidatedForms,
            updateEssayNotesList,
            updateEssay,
        }
    }
}
</script>

<style scoped>

</style>