import {API_URL} from "../../../Constants";

export const retakeRequest = () => {
    return `${API_URL}/quizRetake/request`;
}

export const cancelRetake = (userCanvasId, quizId) => {
    return `${API_URL}/quizRetake/cancel/${userCanvasId}/quiz/${quizId}`;
}
export const userQuizReport = (quizId, userId) => {
    return `${API_URL}/quiz/report/quiz/${quizId}/user/${userId}`;
}

export const allUserQuizReports = (userId) => {
    return `${API_URL}/quiz/report/user/${userId}`;
}